<template>
  <div class="homePage">
    <div class="navbar">
      <h1 class="title">闪滤</h1>
      <div class="button-container">
        <label for="fileInput">
          <span>选择文件</span>
          <input type="file" id="fileInput" ref="fileInput" @change="onFileChange" />
        </label>
        <button class="save_button"><a class="download-link" @click="downloadImage">保存图片</a></button>
      </div>
    </div>
    <div class="content-container">
      <div class="filterLabels">
        <h2>滤镜选项</h2>
        <ul>
          <li v-for="(filter, index) in filters" :key="index">
            <!-- <button @click="applyFilter(filter)">{{ filter.name }}</button> -->
            <FilterCard @click="applyFilter(filter)" :title="filter.name" />
          </li>
        </ul>
      </div>
      <div class="imgCanvas">
        <!-- <h2>结果</h2> -->
        <div class="img_info">
          <p>{{filter_info}}</p>
        </div>
        <canvas class="img_canva" ref="canvas"></canvas>
        <!-- <button @click="uploadImage">上传</button> -->
      </div>
      <!-- <div class="fileLayer">
        <input type="file" ref="fileInput" @change="onFileChange"/>
      </div> -->
    </div>
    <!-- <div class="foodprint">Copyright by xinxin</div> -->
  </div>
</template>

<script>
import axios from 'axios';
import FilterCard from '@/components/FilterCard.vue';

export default {
  components: {
      FilterCard
  },
  data() {
    return {
      filter_info:'请选择图片',
      image: null,
      filtered_img: null,
      filters: [
        { name: '原图', filter: null },
        { name: '1977', filter: '1977' },
        { name: 'Aden', filter: 'Aden' },
        { name: 'Amaro', filter: 'Amaro' },
        { name: 'Ashby', filter: 'Ashby' },
        { name: 'Brannan', filter: 'Brannan' },
        { name: 'Brooklyn', filter: 'Brooklyn' },
        { name: 'Charmes', filter: 'Charmes' },
        { name: 'Clarendon', filter: 'Clarendon' },
        { name: 'Crema', filter: 'Crema' },
        { name: 'Dogpatch', filter: 'Dogpatch' },
        { name: 'Earlybird', filter: 'Earlybird' },
        { name: 'Gingham', filter: 'Gingham' },
        { name: 'Ginza', filter: 'Ginza' },
        { name: 'Hefe', filter: 'Hefe' },
        { name: 'Helena', filter: 'Helena' },
        { name: 'Hudson', filter: 'Hudson' },
        { name: 'Inkwell', filter: 'Inkwell' },
        { name: 'Juno', filter: 'Juno' },
        { name: 'Kelvin', filter: 'Kelvin' },
        { name: 'Lark', filter: 'Lark' },
        { name: 'Lo-Fi', filter: 'Lo-Fi' },
        { name: 'Ludwig', filter: 'Ludwig' },
        { name: 'Mayfair', filter: 'Mayfair' },
        { name: 'Melvin', filter: 'Melvin' },
        { name: 'Nashville', filter: 'Nashville' },
        { name: 'Perpetua', filter: 'Perpetua' },
        { name: 'Reyes', filter: 'Reyes' },
        { name: 'Rise', filter: 'Rise' },
        { name: 'Sierra', filter: 'Sierra' },
        { name: 'Skyline', filter: 'Skyline' },
        { name: 'Slumber', filter: 'Slumber' },
        { name: 'Stinson', filter: 'Stinson' },
        { name: 'Sutro', filter: 'Sutro' },
        { name: 'Toaster', filter: 'Toaster' },
        { name: 'Valencia', filter: 'Valencia' },
        { name: 'Vesper', filter: 'Vesper' },
        { name: 'Walden', filter: 'Walden' },
        { name: 'Willow', filter: 'Willow' },
        { name: 'X-ProII', filter: 'X-ProII' }
      ],
      selectedFilter: null,
    };
  },
  methods: {
    onFileChange(event) {
      console.log('-- select file');
      this.image = event.target.files[0];
      this.applyFilter(null);
    },
    applyFilter(filter) {
      this.selectedFilter = filter;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        if (filter) {
          // apply filter here
          context.filter = filter;
          context.drawImage(image, 0, 0);
          context.filter = 'none';
          console.log('-----applyFilter-----')
          this.filter_info = '处理中';
          console.log(filter)
          this.uploadImage(filter);
        }
      };
      image.src = URL.createObjectURL(this.image);
    },
    uploadImage(filter) {
      const canvas = this.$refs.canvas;
      canvas.toBlob((blob) => {
        // const formData = new FormData();
        // formData.append('image', blob, this.image.name);
        const formData = new FormData();
        formData.append('filtername', this.selectedFilter.filter);
        // formData.append('img', this.image);
        formData.append('img', blob, this.image.name);
        axios.post('http://sparklefilter.top:8000/filter/', formData,  { timeout: 50000 }).then((response) => {
          console.log('上传成功');
          // console.log(response.data.pic)
          // 处理服务器响应
          this.filtered_img = response.data.pic;
          this.show_filtered_img()
          this.filter_info = '处理完成，滤镜：' + filter.name
        }).catch((error) => {
          console.log('上传失败', error);
          // 处理错误
        });
      }, 'image/jpeg', 0.9);
    },
    show_filtered_img(){
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        context.drawImage(image, 0, 0);
      };
      // console.log('-- this filtered_img: ', this.filtered_img)
      if (this.filtered_img != null ){
        // image.src = URL.createObjectURL(this.filtered_img);
        image.src = this.filtered_img
      }
    },
    base64ToImage(base64Data) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        console.log('===> base64 to img')
        image.onload = () => {
          resolve(image);
        };
        image.onerror = (error) => {
          reject(error);
        };
        image.src = base64Data;
      });
    },

    downloadImage() {
      if (this.filtered_img != null){
        const url = URL.createObjectURL(this.filtered_img);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        link.click();
        URL.revokeObjectURL(url);
      }
    }
  },
};
</script>

  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.homePage{
  height: 100%;
  margin: 0;
  padding: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 10%
}
.title {
  float: left;
  margin: 0;
  padding: 10px 0 0 30px;
}
.download-link {
  margin: 0;
  color: black;
}
.foodprint{
  padding: 10px;
}

.content-container {
  margin: 0;
  padding: 0;
  display: flex;
  /* height: 100%; */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the height */
}
.imgCanvas {
  margin: 5px;
  text-align:center;
  /* background-color: #fff; */
  flex: 2;
  height: 100%;
}
.img_canva{
  width:100%;
  height: 100%;
}

.fileLayer{
  margin: 5px;
  flex: 1;
  height: 100%;
  background-color: #fff;
}
.filterLabels{
  margin: 5px;
  background-color: #fff;
  flex: 1;
  height: 100%;
}

.button-container {
  display: flex; /* Enable flexbox layout */
  justify-content: space-between; /* Align items to the ends of the container */
  margin: 0 30px 0 0;
}

</style>
