<template>
    <div class="card" @click="handleCardClick">
      <div class="icon">
        <img :src="iconPath" alt="Icon" :style="{ width: '50px', height: '60px' }" >
      </div>
      <div class="content">
        <p>{{ title }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
      };
    },
    props: {
      title: String,
    },
    computed: {
      iconPath() {
        // 返回图标文件的相对路径
        return process.env.BASE_URL + 'icons/' + this.title + '.jpg';
      }
    },
    methods:{
      handleCardClick() {
        // console.log('click card, flash')
      }
    }
  };
  </script>
  
  <style>
  .card {
    /* 样式定义 */
    width:60px;
    height: 70px;
    margin-top:20px;
    padding-top: 10px;
    background-color:gray;
  }
  
  .icon {
    /* 样式定义 */
    
  }
  
  .content {
    /* 样式定义 */
  }
  </style>